// Variable overrides

// Units size Variables

$base-size: 14;

@function size($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

$base-font-size: size($base-size);

$smallMobileWidth: '320px';

@mixin smallMobile {
  @media (max-width: #{$smallMobileWidth}) {
    @content;
  }
}

// Units size Variables

// Font Families

// @import url('https://fonts.googleapis.com/css2?family=sofia pro:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// $familyPrimary: 'Open Sans', sans-serif;

@font-face {
  font-family: "sofia pro black";
  src: url(../../public/sofiapro/Fonts/sofiapro-black.otf);
}
@font-face {
  font-family: "sofia pro bold";
  src: url(../../public/sofiapro/Fonts/sofiapro-bold.otf);
}
@font-face {
  font-family: "sofia pro semibold";
  src: url(../../public/sofiapro/Fonts/sofiapro-semibold.otf);
}
@font-face {
  font-family: "sofia pro medium";
  src: url(../../public/sofiapro/Fonts/sofiapro-medium.otf);
}
@font-face {
  font-family: "sofia pro regular";
  src: url(../../public/sofiapro/Fonts/sofiapro-regular.otf);
}
@font-face {
  font-family: "sofia pro light";
  src: url(../../public/sofiapro/Fonts/sofiapro-light.otf);
}

$familyPrimaryBlack: 'sofia pro black';
$familyPrimaryBold: 'sofia pro bold';
$familyPrimarySemiBold: 'sofia pro semibold';
$familyPrimaryMedium: 'sofia pro medium';
$familyPrimaryRegular: 'sofia pro regular';
$familyPrimaryLight: 'sofia pro light';

// Font Families

// Frontend Colors //

$tiphubPrimaryTextColor: #000;
$tiphubthemeBlue: #00356b;
$white: #fff;
$twitter: rgb(0, 188, 212);
$green: #3cdb80;
$darkBlue: #033160;
$gray-400: #888888;
$gray-300: #9D9D9D;
$gray-100: #e2e2e2;

// Borders Colors
$border-color: #e2e2e2;
// Borders Colors

// Frontend Colors //

$boxShadow: 0px 0px 22px 10px rgba(227, 229, 232, 0.5);

// Scroll Bar Colors //
::-webkit-scrollbar {
  width: size(5);
  height: size(5)
}

::-webkit-scrollbar-thumb {
  background: grey;
}